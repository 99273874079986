import { FC, lazy, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLoader from '../../../components/shared/page-loader/PageLoader';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { Roles } from '../../../models/Role';
import StorageService from '../../../services/StorageService';
const LoginDone = lazy(() => import('./LoginDone'));
const PasswordLogin = lazy(() => import('./PasswordLogin'));
const TOTPLogin = lazy(() => import('./TOTPLogin'));

type Steps = 'password' | 'otp' | 'done';

export type FlowProps = {
  next: (nextStep: Steps) => void;
};

const LoginFlow: FC = () => {
  const [currentStep, setCurrentStep] = useState<Steps>('password');
  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const hasNavigatedAway = useRef(false);
  const hasPermission = usePermissions();

  const navigateDone = useCallback(() => {
    if (hasNavigatedAway.current) {
      return;
    }

    hasNavigatedAway.current = true;
    StorageService.setLoginUrl();
    const returnPath = query.get('return');
    if (returnPath) {
      navigate(returnPath, { replace: true });
    } else if (hasPermission(Roles.SuperAdmin)) {
      navigate('/admin/iam/organisations', { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  }, [hasPermission, navigate, query]);

  const steps = useMemo<Record<Steps, JSX.Element>>(
    () => ({
      password: <PasswordLogin next={setCurrentStep} />,
      otp: <TOTPLogin next={setCurrentStep} />,
      done: <LoginDone navigateDone={navigateDone} />,
    }),
    [navigateDone],
  );

  return <Suspense fallback={<PageLoader loading isSuspense />}>{steps[currentStep]}</Suspense>;
};

export default LoginFlow;
